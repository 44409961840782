export default [
 
  {
    // path: "/#services",
    // text: "Services",
  },

  {
    // path: "/#contact",
    // text: "contact",
  },
]
